<template>
  <section class="Content-Page">
    <Loader :loading="showLoader" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-title">
            <div class="row">
              <div class="col-12 col-md-3">
                <h3 class="page-title">PayPal</h3>
              </div>
            </div>
          </div>
          <div class="table-responsive pb-2">
            <table class="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th>Currency</th>
                  <th>Live Client Id</th>
                  <th>Locale</th>
                  <th>Live Client Secret</th>
                  <th>SandBox Client Id</th>
                  <th>SandBox Client Secret</th>
                  <th>SandBox Mode</th>
                  <th>Notify URL</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody v-if="info">
                <tr>
                  <td>
                    {{ info.PAYPAL_CURRENCY }}
                  </td>
                  <td>
                    {{ info.PAYPAL_LIVE_CLIENT_ID }}
                  </td>
                  <td>
                    {{ info.PAYPAL_LOCALE }}
                  </td>
                  <td>
                    {{ info.PAYPAL_LIVE_CLIENT_SECRET }}
                  </td>
                  <td>
                    {{ info.PAYPAL_SANDBOX_CLIENT_ID }}
                  </td>
                  <td>
                    {{ info.PAYPAL_SANDBOX_CLIENT_SECRET }}
                  </td>
                  <td>
                    {{ info.PAYPAL_SANDBOX_MODE }}
                  </td>
                  <td>
                    {{ info.PAYPAL_NOTIFY_URL }}
                  </td>
                  <td>
                    <router-link
                      class="badge badge-info"
                      :to="`/settings/edit-paypal`"
                      >Edit
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col-12 p-3 text-center" v-if="info == ''">
            No data found!
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { setingsApi } from "../../../api";
import Loader from "../../../components/Loader";
export default {
  name: "content-page",
  components: {
    Loader,
  },
  data() {
    return {
      info: null,
      showLoader: false,
    };
  },
  mounted() {
    this.list();
  },
  methods: {
    async list() {
      this.showLoader = true;
      const { data } = await setingsApi.paypal();
      this.info = data.data.data;
      this.showLoader = false;
    },
  },
};
</script>

<style scoped></style>
